import { defineStore } from "pinia";

export const useTagStore = defineStore('isolatedTag', {
    state: () => (
        { 
            posts: null,
            apiCalls: 0,
            openedPostViewCloseHandler: false,
            openedPost: null,
            tagNameCache: null            
        }
    ),
    getters: {

    },
    actions: {

    }
});